//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InvoiceReceiptTable from '@/modules/invoice/components/invoice-receipt-table.vue';
import InvoicePaymentTable from '@/modules/invoice/components/invoice-payment-table.vue';
import moment from 'moment';
export default {
  components: {
    [InvoiceReceiptTable.name]: InvoiceReceiptTable,
    [InvoicePaymentTable.name]: InvoicePaymentTable,
  },
  data() {
    return {
      result: {
        createdAt: '2022-02-09T13:59:31.045Z',
        id: 'edICjUvouYqKs0gEg2R2',
        receiptNum: 743,
        trip: {
          id: 'fyTcpJkKSxXqoWLrVlp1',
          time: '2022-02-25T13:00:00.000Z',
          type: 'group',
          price: 1150,
        },
        paymentDetails: {
          transactionId: '1209319156960',
          paymentMethod: 'wallet',
          tax: 189.75,
          saryahProfit: 165,
          amountToOwner: 1100,
          saryahFeeRate: 0.15,
          taxRate: 0.15,
          untaxedAmount: 1265,
          total: 1454.75,
        },
        client:{
          name:'Ahmed Client',
          email:'Ahmed@gmail.com',
          phone:'+966123456',
        },
        guide:{
          name:'Ahmed Client',
          email:'Ahmed@gmail.com',
          phone:'+966123456',
        },
        receiptDetails:[
          {
            description:"Trip (Group)",
            count:1,
            price:1150,
            subtotal:1150,
          },
          {
            description:"Trip (VIP)",
            count:1,
            price:115,
            subtotal:115,
          }
        ]
      },
    };
  },
  methods:{
    presenterDate(val) {
      return val ? moment(val).locale('en').format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDateTime(val) {
      return val ? moment(val).locale('en').format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
  }
};
