//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import i18n from '@/vueI18n';

export default {
  name: 'app-invoice-payment-table',
  props:['data'],
  data() {
    return {
      columns: [
        {
          name: 'total',
          required: true,
          label: 'Total',
          align: 'center',
          field: 'total',
        },
        {
          name: 'saryahProfit',
          required: true,
          label: 'App Profit',
          align: 'center',
          field: 'saryahProfit',
        },
        {
          name: 'taxFees',
          required: true,
          label: 'Tax Fees',
          align: 'center',
          field: 'taxFees',
        },
        {
          name: 'amountToOwner',
          align: 'center',
          label: 'Amount To Owner',
          field: 'amountToOwner',
        },
        {
          name: 'paymentMethod',
          align: 'center',
          label: 'Payment Method',
          field: 'paymentMethod',
        },
      ],
    //   data: [
    //     {
    //       total: 1400,
    //       saryahProfit: 100,
    //       taxFees: '15%',
    //       amountToOwner: 40,
    //       paymentMethod: 40,
    //     }
    //   ],
    };
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
  },

  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      tripsTransactions: 'payment/list/tripsTransactions',
      countTripsTransactions:
        'payment/list/countTripsTransactions',
      loadingTripsTransactions:
        'payment/list/loadingTripsTransactions',
      tripsTransactionsFirstPage:
        'payment/list/tripsTransactionsFirstPage',
      tripsTransactionsLastPage:
        'payment/list/tripsTransactionsLastPage',
    }),
    language() {
      return i18n.locale;
    },
  },
};
